import React, { useEffect, useLayoutEffect, useState } from 'react';
// Modules
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Card, CardGroup, Col, Container, Row, Form } from 'react-bootstrap';
import { useSetRecoilState } from 'recoil';
import { currentUserState } from '../../states/currentUser';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { getUser, login } from '../../services/AuthService';
import { CurrentUser } from '../../interfaces/user';
import { Url } from '../../constants/Url';
import { administratedCookieSave } from '../../services/IdentityCookieService';
import { LAST_PATH } from '../../Constants';
import { Alert } from '../atoms/Alert';
import { getHistoryState } from '../../utils/functions';
import { IncAccountApi } from '../../api-client';

export type IFormDatas = {
  username: string;
  password: string;
};

export type Authorities = {
  id: number;
  name: string;
  email: string;
};

export interface Refferer {
  referrer: {
    pathname: string;
  };
}

// React Router の history API が React Hooks の一種として使える.
const LoginPage: React.FC = () => {
  // URL情報取得
  const { isAuthChecking } = useCurrentUser();
  const setCurrentUser = useSetRecoilState(currentUserState);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IFormDatas>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
  });
  // React-Router-Domの機能
  const history = useHistory();
  const incAccountApi = new IncAccountApi();
  const [failedLogin, setFailedLogin] = useState(false);
  // const failedLogin = getHistoryState(history.location.state, 'failedLogin');
  const [onSubmitted, setOnSubmitted] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const v = getHistoryState(history.location.state, 'failedLogin');
    if (v !== undefined) {
      setFailedLogin(v as boolean);
    }
  }, [location]);
  // 直前のURL取得
  // const beforePath = (currentLocation.state as Refferer)
  // const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
  // const onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
  // const onSubmit = handleSubmit((data) => console.log(data));
  // const onSubmit: SubmitHandler<IFormDatas> = (data) => { console.log(data) }
  const onSubmit: SubmitHandler<IFormDatas> = (data) => {
    // submit ボタンのデフォルトの振る舞い (GET や POST) を抑制する
    setOnSubmitted(true);

    // ログイン処理
    login(data).then((result) => {
      // ユーザ情報取得
      getUser().then((res) => {
        if (res != null && res !== false) {
          const auth = res as CurrentUser;
          setCurrentUser(auth);
          Promise.all([administratedCookieSave(), incAccountApi.passwordCheck()]).then((r) => {
            console.log(r);
            const passwordCheckResult = r[1];
            if (!passwordCheckResult.data.result) history.push(Url.PASSWORD_EDIT);
          });
          const path = localStorage.getItem(LAST_PATH) || '/my';
          if (typeof auth.apiclientId === 'number') {
            history.push(Url.OEM.TOP);
          } else if (path === Url.LOGIN || path === Url.LOGOUT) {
            history.push(Url.TOP);
          } else {
            history.push(path);
          }
        }
      });
    });
  };
  useLayoutEffect(() => {}, []);
  return (
    <>
      {!isAuthChecking ? (
        <div className="app-body c-default-layout flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="4">
                <CardGroup>
                  <Card className="p-4">
                    <Card.Body>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <h1>ログイン</h1>
                        <p className="text-muted">ログインしてください。</p>
                        {onSubmitted && failedLogin && Object.keys(errors).length === 0 && (
                          <Alert variant="danger">メールアドレスかパスワードに誤りがあります。</Alert>
                        )}
                        <div className="mb-3">
                          <div className="mb-3">
                            <input
                              type="email"
                              id="username"
                              placeholder="メールアドレス"
                              {...register('username', {
                                required: {
                                  value: true,
                                  message: 'ユーザ名は必須です。',
                                },
                                // pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'メールアドレスの形式が間違っています。'},
                                minLength: {
                                  value: 1,
                                  message: '1文字以上入力してください。',
                                },
                              })}
                              className={`form-control ${errors.username && 'is-invalid'}`}
                            />
                            {errors.username && (
                              <Form.Control.Feedback type="invalid">{errors.username.message}</Form.Control.Feedback>
                            )}
                          </div>
                          <div className="mb-3">
                            <input
                              type="password"
                              id="password"
                              placeholder="パスワード"
                              {...register('password', {
                                required: {
                                  value: true,
                                  message: 'パスワードは必須です。',
                                },
                                // pattern: { value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/, message: '半角英小文字大文字数字をそれぞれ1種類以上含む8文字以上100文字以下の正規表現'},
                                minLength: {
                                  value: 8,
                                  message: '8文字以上入力してください。',
                                },
                              })}
                              className={`form-control ${errors.password && 'is-invalid'}`}
                            />
                            {errors.password && (
                              <Form.Control.Feedback type="invalid">{errors.password.message}</Form.Control.Feedback>
                            )}
                          </div>
                          <div>
                            <Button color="primary" className="px-4" type="submit">
                              ログイン
                            </Button>
                          </div>
                        </div>
                      </form>
                    </Card.Body>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </>
  );
};

export default LoginPage;
