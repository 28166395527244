import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import './App.css';
import { Url } from './constants/Url';

// ページのインポート ここから
/* eslint-disable import/no-cycle */
import 'bootstrap/dist/css/bootstrap.min.css';
import GuestRoute from './components/organisms/GuestRoute';
import PrivateRoute from './components/organisms/PrivateRoute';
import { TopPage } from './components/pages/TopPage';
import { CategoryTopPage } from './components/pages/CategoryTopPage';
import { AboutOpeningPage } from './components/pages/AboutOpeningPage';
import { ApiClientConfirmPage } from './components/pages/ApiClient/ApiClientConfirmPage';
import { ApiClientListPage } from './components/pages/ApiClient/ApiClientListPage';
import { ApplicationModifyPage } from './components/pages/Application/ApplicationModifyPage';
import { ApplicationPage } from './components/pages/Application/ApplicationPage';
import { BillingAgentDepositDownloadPage } from './components/pages/Billing/BillingAgentDepositDownload';
import { BillingAgentDepositUploadPage } from './components/pages/Billing/BillingAgentDepositUpload';
import { BillingAgentDetailPage } from './components/pages/Billing/BillingAgentDetailPage';
import { BillingAgentListPage } from './components/pages/Billing/BillingAgentListPage';
import { BillingAgentFeeListPage } from './components/pages/Billing/BillingAgentFeeListPage';
import { BillingAgentFeeDetailPage } from './components/pages/Billing/BillingAgentFeeDetailPage';
import { BillingFormDownload } from './components/pages/Billing/BillingFormDownload';
import { BillingInvoiceDetail } from './components/pages/Billing/BillingInvoiceDetail';
import { BillingListPage } from './components/pages/Billing/BillingListPage';
import { BillingPaidApplyPage } from './components/pages/Billing/BillingPaidApplyPage';
import { BillingDestinationListPage } from './components/pages/Billing/BillingDestinationListPage';
import { BlankPage } from './components/pages/BlankPage';
import { BrandDetailModifyPage } from './components/pages/Brand/BrandDetailModifyPage';
import { BrandOemPage } from './components/pages/Brand/BrandOemPage';
import { BrandPage } from './components/pages/Brand/BrandPage';
import { BrandRichPage } from './components/pages/Brand/BrandRichPage';
import { CampaignDetailModifyPage } from './components/pages/Campaign/CampaignDetailModifyPage';
import { CampaignOemPage } from './components/pages/Campaign/CampaignOemPage';
import { CampaignPage } from './components/pages/Campaign/CampaignPage';
import { CampaignRichPage } from './components/pages/Campaign/CampaignRichPage';
import { ClientOrganizationSettingPage } from './components/pages/ClientSetting/ClientOrganizationSettingPage';
import { ClientAccountSettingPage } from './components/pages/ClientSetting/ClientAccountSettingPage';
import { ClientPage } from './components/pages/Client/ClientPage';
import { ClientModifyPage } from './components/pages/Client/ClientModifyPage';
import { ContractReservationPage } from './components/pages/MonitorList/ContractReservationPage';
import { EnqueteDetailModifyPage } from './components/pages/Enquete/EnqueteDetailModifyPage';
import { EnqueteListPage } from './components/pages/Enquete/EnqueteListPage';
import ErrorPage from './components/pages/ErrorPage';
import { ClientOfRequiredNumberPage } from './components/pages/FloatingReward/ClientOfRequiredNumberPage';
import { ClientOfRequiredNumberTargetPage } from './components/pages/FloatingReward/ClientOfRequiredNumberTargetPage';
import { FloatingRewardPage } from './components/pages/FloatingReward/FloatingRewardPage';
import { FloatingRewardUploadConfirmPage } from './components/pages/FloatingReward/FloatingRewardUploadConfirmPage';
import { ForceUnseatedPage } from './components/pages/ForceLottery/ForceUnseatedPage';
import { ForceWinningPage } from './components/pages/ForceLottery/ForceWinningPage';
import { AnnouncePage } from './components/pages/Announce/AnnouncePage';
import { AnnounceModifyPage } from './components/pages/Announce/AnnounceModifyPage';
import { ManagementAnnouncePage } from './components/pages/ManagementAnnounce/ManagementAnnouncePage';
import { ManagementAnnounceModifyPage } from './components/pages/ManagementAnnounce/ManagementAnnounceModifyPage';
import { BannerListPage } from './components/pages/Banner/BannerListPage';
import { BannerItemModifyPage } from './components/pages/Banner/BannerItemModifyPage';
import { BannerCommonPage } from './components/pages/Banner/BannerCommonPage';
import { BannerPriorityModifyPage } from './components/pages/Banner/BannerPriorityModifyPage';
import { BannerInsitePage } from './components/pages/Banner/BannerInsitePage';
import { HolidayManagePage } from './components/pages/HolidayManagePage';
import { JobHistoryPage } from './components/pages/JobHistoryPage';
import Login from './components/pages/LoginPage';
import Logout from './components/pages/LogoutPage';
import MaintenancePage from './components/pages/MaintenancePage';
import { MonitorBaseModifyPage } from './components/pages/Monitor/MonitorBaseModifyPage';
import { MonitorRuleSetPage } from './components/pages/MonitorRule/MonitorRuleSetPage';
import { MonitorRuleSetModifyPage } from './components/pages/MonitorRule/MonitorRuleSetModifyPage';
import { MonitorListPage } from './components/pages/MonitorList/MonitorListPage';
import { MonitorApplyHistoryListPage } from './components/pages/MonitorList/MonitorApplyHistoryListPage';
import My from './components/pages/MyPage';
import { PriorEnqueteFilePage } from './components/pages/EnqueteReceiptDownload/PriorEnqueteFilePage';
import { PostEnqueteFilePage } from './components/pages/EnqueteReceiptDownload/PostEnqueteFilePage';
import { ReceiptFilePage } from './components/pages/EnqueteReceiptDownload/ReceiptFilePage';
import { EnqueteFilePage } from './components/pages/EnqueteReceiptDownload/EnqueteFilePage';
import { PointExchangePage } from './components/pages/PointExchangePage';
import { PostingRequestListPage } from './components/pages/PostingRequest/PostingRequestListPage';
import { PostingRequestPage } from './components/pages/PostingRequest/PostingRequestPage';
import { PostingReservationListPage } from './components/pages/PostingRequest/PostingReservationListPage';
import { PostingReservationPage } from './components/pages/PostingRequest/PostingReservationPage';
import { QuestionListPage } from './components/pages/QuestionLibrary/QuestionListPage';
import { ListSample } from './components/pages/Sample/ListSample';
import PdfTestPage from './components/pages/Sample/PdfTestPage';
import { RegisterList } from './components/pages/Sample/RegisterList';
import { RegisterListCard } from './components/pages/Sample/RegisterListCard';
import { RegisterListCardAccordion } from './components/pages/Sample/RegisterListCardAccordion';
import { RegisterListCardAccordionComplex } from './components/pages/Sample/RegisterListCardAccordionComplex';
import { RegisterListCardComplex } from './components/pages/Sample/RegisterListCardComplex';
import { RegisterListComplex } from './components/pages/Sample/RegisterListComplex';
import { RegisterTable } from './components/pages/Sample/RegisterTable';
import { RegisterTableCard } from './components/pages/Sample/RegisterTableCard';
import { RegisterTableCardAccordion } from './components/pages/Sample/RegisterTableCardAccordion';
import { RegisterTableCardAccordionComplex } from './components/pages/Sample/RegisterTableCardAccordionComplex';
import { RegisterTableCardComplex } from './components/pages/Sample/RegisterTableCardComplex';
import { RegisterTableComplex } from './components/pages/Sample/RegisterTableComplex';
import SamplePage from './components/pages/Sample/SamplePage';
import { SampleTopPage } from './components/pages/Sample/SampleTopPage';
import { SelectList } from './components/pages/Sample/SelectList';
import { SelectTable } from './components/pages/Sample/SelectTable';
import { ShopReplacePage } from './components/pages/ShopReplacePage';
import { SqlQueryCompletionPage } from './components/pages/SqlQuery/SqlQueryCompletionPage';
import { SqlQueryCreateConfirmPage } from './components/pages/SqlQuery/SqlQueryCreateConfirmPage';
import { SqlQueryCreatePage } from './components/pages/SqlQuery/SqlQueryCreatePage';
import { SqlQueryDeleteConfirmPage } from './components/pages/SqlQuery/SqlQueryDeleteConfirmPage';
import { SqlQueryInfoPage } from './components/pages/SqlQuery/SqlQueryInfoPage';
import { SqlQueryPage } from './components/pages/SqlQuery/SqlQueryPage';
import { SqlQueryUpdateConfirmPage } from './components/pages/SqlQuery/SqlQueryUpdateComfirmPage';
import { SqlQueryUpdatePage } from './components/pages/SqlQuery/SqlQueryUpdatePage';
import { SqlTemplateCompletionPage } from './components/pages/SqlTemplate/SqlTemplateCompletionPage';
import { SqlTemplateCreateConfirmPage } from './components/pages/SqlTemplate/SqlTemplateCreateConfirmPage';
import { SqlTemplateCreatePage } from './components/pages/SqlTemplate/SqlTemplateCreatePage';
import { SqlTemplateDeleteConfirmPage } from './components/pages/SqlTemplate/SqlTemplateDeleteConfimPage';
import { SqlTemplateInfoPage } from './components/pages/SqlTemplate/SqlTemplateInfoPage';
import { SqlTemplatePage } from './components/pages/SqlTemplate/SqlTemplatePage';
import { SqlTemplateUpdateConfirmPage } from './components/pages/SqlTemplate/SqlTemplateUpdateConfirmPage';
import { SqlTemplateUpdatePage } from './components/pages/SqlTemplate/SqltemplateUpdatePage';
// eslint-disable-next-line import/no-cycle
import { ApplyHistoryListPage } from './components/pages/ApplyHistory/ApplyHistoryListPage';
import { ApplyInfoPage } from './components/pages/CustomerInfo/ApplyInfoPage';
import { ExcecutiveStaffApplyManagementPage } from './components/pages/ApplyManagement/ExcecutiveStaffApplyManagementPage';
import { QcCrewApplyManagementPage } from './components/pages/ApplyManagement/QcCrewApplyManagementPage';
import { ExecutiveStaffApplySearchPage } from './components/pages/ApplySearch/ExecutiveStaffApplySearchPage';
import { QcCrewApplySearchPage } from './components/pages/ApplySearch/QcCrewApplySearchPage';
import { CorrectionReceiptPage } from './components/pages/Correction/CorrectionReceiptPage';
import { CorrectionEnquetePage } from './components/pages/Correction/CorrectionEnquetePage';
import { CorrectionDistributePage } from './components/pages/Correction/CorrectionDistributePage';
import { BulkRejectPage } from './components/pages/BulkRejectPage';
import { ClientNgEditPage } from './components/pages/ClientNg/ClientNgEditPage';
import { ClientNgPage } from './components/pages/ClientNg/ClientNgPage';
import { ExtendDeadlineHistoryPage } from './components/pages/ExtendDeadline/ExtendDeadlineHistoryPage';
import { ExtendDeadlineRegisterPage } from './components/pages/ExtendDeadline/ExtendDeadlineRegisterPage';
import { GiftTicketPage } from './components/pages/GiftTicketPage';
import { PaidServicePointReturnHistoryPage } from './components/pages/PaidServicePointReturn/PaidServicePointReturnHistoryPage';
import { PaidServicePointReturnRegisterPage } from './components/pages/PaidServicePointReturn/PaidServicePointReturnRegisterPage';
import { PointGrantPage } from './components/pages/Point/PointGrantPage';
import { PointHistoryPage } from './components/pages/Point/PointHistoryPage';
import { RedirectPage } from './components/pages/RedirectPage';
import { ContractShopConfirmPage } from './components/pages/Shop/ContractShopConfirmPage';
import { ContractShopListPage } from './components/pages/Shop/ContractShopListPage';
import { ShopConfirmPage } from './components/pages/Shop/ShopConfirmPage';
import { ShopListPage } from './components/pages/Shop/ShopListPage';
import { YuucomiManagerPage } from './components/pages/YuucomiManagerPage';
import { YuucomiSuperiorPage } from './components/pages/YuucomiSuperiorPage';
import { MailBulkDeliveryListPage } from './components/pages/MailBulkDelivery/MailBulkDeliveryListPage';
import { MailBulkDeliveryDetailPage } from './components/pages/MailBulkDelivery/MailBulkDeliveryDetailPage';
import { MailListPage } from './components/pages/MailMagazine/MailListPage';
import { DirectMailDetailModifyPage } from './components/pages/MailMagazine/DirectMailDetailModifyPage';
import { F3MailDetailModifyPage } from './components/pages/MailMagazine/F3MailDetailModifyPage';
import { CustomerListPage } from './components/pages/Customer/CustomerListPage';
import { CustomerInfoPage } from './components/pages/Customer/CustomerInfoPage';
import { RelatedCustomerListPage } from './components/pages/Customer/RelatedCustomerListPage';
import { CustomerAuthHistoryPage } from './components/pages/Customer/CustomerAuthHistoryPage';
import { AgentLoginPage } from './components/pages/Customer/AgentLoginPage';
import { LoginHistoryPage } from './components/pages/Customer/LoginHistoryPage';
import { MailHistoryListRoiPage } from './components/pages/MailHistory/MailHistoryListRoiPage';
import { MailHistoryListOemPage } from './components/pages/MailHistory/MailHistoryListOemPage';
import { F4MailDetailModifyPage } from './components/pages/MailMagazine/F4MailDetailModifyPage';
import { F5MailDetailModifyPage } from './components/pages/MailMagazine/F5MailDetailModifyPage';
import { MailTemplateListPage } from './components/pages/MailMagazine/MailTemplateListPage';
import { MailTemplateModifyPage } from './components/pages/MailMagazine/MailTemplateModifyPage';
import { INC_CATEGORY } from './Constants';
import { BillingInvoiceDestRelationPage } from './components/pages/Billing/BillingInvoiceDestRelationPage';
import { ImagePreviewPage } from './components/pages/ImagePreviewPage';
import { BillingDestinationDetailPage } from './components/pages/Billing/BillingDestinationDetailPage';
import { CommentPage } from './components/pages/PostingRequest/CommentPage';
import { SurveyProofImageListPage } from './components/pages/Correction/SurveyProofImageListPage';
import { MonitorRuleSetDtailPage } from './components/pages/MonitorRule/MonitorRuleSetDtailPage';
import { ReceiptPriceModifyPage } from './components/pages/ReceiptPrice/ReceiptPriceModifyPage';
import { PassworEdit } from './components/pages/PassswordEdit';
import { BulkStatusReservationPage } from './components/pages/PostingRequest/BulkStatusReservationPage';
import { CorrectSettingSearchPage } from './components/pages/CorrectSetting/CorrectSettingSearchPage';
import { CorrectSettingEachMonitorPage } from './components/pages/CorrectSetting/CorrectSettingEachMonitorPage';
import { CorrectSettingEachClientPage } from './components/pages/CorrectSetting/CorrectSettingEachClientPage';
import { CorrectDataInputPage } from './components/pages/Correction/CorrectDataInputPage';
import { CorrectDataInputWcheckPage } from './components/pages/Correction/CorrectDataInputWcheckPage';
import { CorrectDataInputTargetListPage } from './components/pages/Correction/CorrectDataInputTargetListPage';
import { CorrectPhoneMasterPage } from './components/pages/Correction/CorrectPhoneMasterPage';
import { AnswerHistoryListPage } from './components/pages/Correction/AnswerHistoryListPage';
import { AnswerListPage } from './components/pages/Correction/AnswerListPage';
import { DataInputDistributePage } from './components/pages/Correction/DataInputDitributePage';
import { DataInputWCheckDstributePage } from './components/pages/Correction/DataInputWCheckDstributePage';
import { OemFeeListPage } from './components/pages/EventMessage/OemFeeListPage';
import { BillingAgentMonitorBaseRelationPage } from './components/pages/Billing/BillingAgentMonitorBaseRelationPage';
import { OemTopPage } from './components/pages/OemTopPage';
import OemPrivateRoute from './components/organisms/OemPrivateRoute';
import { OemEventMessageListPage } from './components/pages/Oem/OemEventMessageListPage';
import { OemMonitorListPage } from './components/pages/Oem/OemMonitorListPage';
import { OemApplyInfoListPage } from './components/pages/Oem/OemApplyInfoListPage';
import { MonitorApiClientsSettingPage } from './components/pages/Monitor/MonitorApiClientsSettingPage';
import { IncOemTopPage } from './components/pages/IncOemTopPage';
import { OemBalanceListPage } from './components/pages/OemBalance/OemBalanceListPage';
import { OemBalancePage } from './components/pages/OemBalance/OemBalancePage';
/* eslint-enable import/no-cycle */
// ページのインポート ここまで

// ヒストリをRouterに持たせるためAppで定義している
export const history = createBrowserHistory();
// 毎回同じ結果を返すかつよく使われるAPIをキャッシュ化する
// https://tanstack.com/query/v4/docs/react/overview
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5分
      cacheTime: 10 * 60 * 1000, // 10分
    },
  },
});

const useDisableScrollOnNumberInput = () => {
  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if ((e.target as HTMLElement).tagName === 'INPUT' && (e.target as HTMLInputElement).type === 'number') {
        (e.target as HTMLInputElement).blur(); // ホイール操作でフォーカスを外す
      }
    };
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);
};

// Appコンポーネント
const App: React.FC = () => {
  useDisableScrollOnNumberInput();

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Router history={history}>
          <Switch>
            <PrivateRoute exact path="/">
              <TopPage />
            </PrivateRoute>

            <Route path={Url.COMMON_ERROR}>
              <ErrorPage />
            </Route>

            <Route path={Url.MAINTENANCE}>
              <MaintenancePage />
            </Route>

            <Route path={Url.REDIRECT}>
              <RedirectPage />
            </Route>

            <Route path={Url.IMAGE_PREVIEW}>
              <ImagePreviewPage />
            </Route>

            <PrivateRoute path={Url.PASSWORD_EDIT}>
              <PassworEdit />
            </PrivateRoute>

            {/* 未ログインのユーザのみがアクセスできる */}
            <GuestRoute path="/login">
              <Login />
            </GuestRoute>
            {/* ログイン済みのユーザのみがアクセスできる */}
            <PrivateRoute path="/logout">
              <Logout />
            </PrivateRoute>

            <PrivateRoute path="/my">
              <My />
            </PrivateRoute>

            <PrivateRoute path="/pdf">
              <PdfTestPage />
            </PrivateRoute>

            <PrivateRoute path="/sample">
              <SamplePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.TOP}>
              <CategoryTopPage category={INC_CATEGORY.KEISAI} />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.TOP}>
              <CategoryTopPage category={INC_CATEGORY.UCOMI} />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.QCTOP}>
              <CategoryTopPage category={INC_CATEGORY.QCQREW} />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.DATA_INPUT_WORKER}>
              <CategoryTopPage category={INC_CATEGORY.DATA_INPUT_WORKER} />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.ENQUETE}>
              <EnqueteListPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.ENQUETE_MODIFY}/:type/:enqueteId?`}>
              <EnqueteDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.ABOUT_OPENING}>
              <AboutOpeningPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.APPLICATION}>
              <ApplicationPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.APPLICATION_MODIFY}>
              <ApplicationModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.POINT_EXCHANGE}>
              <PointExchangePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.PRIOR_ENQUETE_FILE}>
              <PriorEnqueteFilePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.POST_ENQUETE_FILE}>
              <PostEnqueteFilePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.RECEIPT_FILE}>
              <ReceiptFilePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.ENQUETE_FILE}>
              <EnqueteFilePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CLIENT_OF_REQUIRED_NUMBER}>
              <ClientOfRequiredNumberPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CLIENT_OF_REQUIRED_NUMBER_TARGET}>
              <ClientOfRequiredNumberTargetPage />
            </PrivateRoute>

            <PrivateRoute path={Url.KEISAI.CONTRACT_SHOP}>
              <ContractShopListPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.KEISAI.CONTRACT_SHOP_CONFIRM}/:id`}>
              <ContractShopConfirmPage />
            </PrivateRoute>

            <PrivateRoute path={Url.KEISAI.SHOP}>
              <ShopListPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.KEISAI.SHOP_CONFIRM}/:id`}>
              <ShopConfirmPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.FLOATING_REWARD}/:id`}>
              <FloatingRewardPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.FLOATING_REWARD_UPLOAD_CONFIRM}>
              <FloatingRewardUploadConfirmPage />
            </PrivateRoute>

            <PrivateRoute path={Url.KEISAI.QUESTION}>
              <QuestionListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.BILLING.BILLING_PAID_APPLY}>
              <BillingPaidApplyPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.BILLING.BILLING_LIST}/:role`}>
              <BillingListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.BILLING.BILLING_DESTINATION}>
              <BillingDestinationListPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.BILLING.BILLING_DESTINATION_DETAIL}/:id`}>
              <BillingDestinationDetailPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.BILLING.BILLING_INVOICE_DETAIL}/:id`}>
              <BillingInvoiceDetail />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.BRAND}>
              <BrandPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.KEISAI.BRAND_DETAIL}/:brandId`}>
              <BrandDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.KEISAI.BRAND_MODIFY}/:brandId?`}>
              <BrandDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.BRAND_OEM}>
              <BrandOemPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.BRAND_RICH}>
              <BrandRichPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.BULK_STATUS_RESERVATION}>
              <BulkStatusReservationPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CAMPAIGN}>
              <CampaignPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CAMPAIGN_DETAIL}>
              <CampaignDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CAMPAIGN_MODIFY}>
              <CampaignDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CAMPAIGN_OEM}>
              <CampaignOemPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CLIENT_ORGANIZATION_SETTING}>
              <ClientOrganizationSettingPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CLIENT_ACCOUNT_SETTING}>
              <ClientAccountSettingPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CAMPAIGN_RICH}>
              <CampaignRichPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CLIENT}>
              <ClientPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.CLIENT_DETAIL}/:clientId?`}>
              <ClientModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CAMPAIGN}>
              <CampaignPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.CONTRACT_RESERVATION}>
              <ContractReservationPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.FORCE_WINNING}>
              <ForceWinningPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.FORCE_UNSEATED}>
              <ForceUnseatedPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.MAIL}>
              <MailListPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.MAIL_TEMPLATE}>
              <MailTemplateListPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.MAIL_TEMPLATE_MODIFY}/:templateId?`}>
              <MailTemplateModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.DIRECTMAIL_DETAIL}>
              <DirectMailDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.DIRECTMAIL_MODIFY}>
              <DirectMailDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.F3MAIL_DETAIL}>
              <F3MailDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.F3MAIL_MODIFY}>
              <F3MailDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.F4MAIL_DETAIL}>
              <F4MailDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.F4MAIL_MODIFY}>
              <F4MailDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.F5MAIL_DETAIL}>
              <F5MailDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.F5MAIL_MODIFY}>
              <F5MailDetailModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.MONITOR_RULE_SET}>
              <MonitorRuleSetPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.MONITOR_RULE_SET_MODIFY}/:ruleSetId?`}>
              <MonitorRuleSetModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.MONITOR_RULE_SET_DETAIL}/:ruleSetId`}>
              <MonitorRuleSetDtailPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.MONITOR_BASE_MODIFY}>
              <MonitorBaseModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.MONITOR_LIST}>
              <MonitorListPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.MONITOR_APPLY_HISTORY_LIST}>
              <MonitorApplyHistoryListPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.MONITOR_APICLIENTS}>
              <MonitorApiClientsSettingPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.POSTING_REQUEST_LIST}>
              <PostingRequestListPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.POSTING_REQUEST}>
              <PostingRequestPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.POSTING_RESERVATION_LIST}>
              <PostingReservationListPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.POSTING_RESERVATION}/:id`}>
              <PostingReservationPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.MANAGEMENT_ANNOUNCE}>
              <ManagementAnnouncePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.MANAGEMENT_ANNOUNCE_MODIFY}>
              <ManagementAnnounceModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.ANNOUNCE}>
              <AnnouncePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.ANNOUNCE_MODIFY}>
              <AnnounceModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.BANNER_LIST}>
              <BannerListPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.BANNER_ITEM_MODIFY}/:id/:bannerTypeId`}>
              <BannerItemModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.BANNER_ITEM_MODIFY}/:id/:bannerTypeId/:categoryId`}>
              <BannerItemModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.BANNER_COMMON}>
              <BannerCommonPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.BANNER_PRIORITY_MODIFY}>
              <BannerPriorityModifyPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.BANNER_INSITE}>
              <BannerInsitePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.HOLIDAY_MANAGE}>
              <HolidayManagePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.OEM_BALANCE_LIST}>
              <OemBalanceListPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.OEM_BALANCE}/:id?`}>
              <OemBalancePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.BILLING.HOLIDAY_MANAGE}>
              <HolidayManagePage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.KEISAI.COMMENT}/:postingRequestId`}>
              <CommentPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.SHOP_REPLACE}>
              <ShopReplacePage />
            </PrivateRoute>

            <PrivateRoute path={Url.BILLING.JOB_HISTORY}>
              <JobHistoryPage />
            </PrivateRoute>

            <PrivateRoute path={Url.BILLING.BILLING_AGENT_LIST}>
              <BillingAgentListPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.BILLING.BILLING_AGENT_MONITOR_BASE_LIST}/:id`}>
              <BillingAgentMonitorBaseRelationPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.BILLING.BILLING_AGENT_DETAIL}/:id`}>
              <BillingAgentDetailPage />
            </PrivateRoute>

            <PrivateRoute path={Url.BILLING.BILLING_AGENT_FEE_LIST}>
              <BillingAgentFeeListPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.BILLING.BILLING_AGENT_FEE_DETAIL}/:id`}>
              <BillingAgentFeeDetailPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.GIFT_TICKET}>
              <GiftTicketPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.GIFT_TICKET}/:id`}>
              <GiftTicketPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.PAID_SERVICE_POINT_RETURN_HISTORY}>
              <PaidServicePointReturnHistoryPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.PAID_SERVICE_POINT_RETURN_REGISTER}>
              <PaidServicePointReturnRegisterPage />
            </PrivateRoute>

            <PrivateRoute path={Url.BILLING.DEPOSIT_DOWNLOAD}>
              <BillingAgentDepositDownloadPage />
            </PrivateRoute>

            <PrivateRoute path={Url.BILLING.DEPOSIT_UPLOAD}>
              <BillingAgentDepositUploadPage />
            </PrivateRoute>

            <PrivateRoute path={Url.BILLING.BILLING_FORM_DOWNLOAD}>
              <BillingFormDownload />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.BILLING.YUUCOMI_MANAGER}/:type`}>
              <YuucomiManagerPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.BILLING.YUUCOMI_MANAGER}/:type/:billingHeaderId`}>
              <YuucomiManagerPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.BILLING.YUUCOMI_SUPERIOR}/:type`}>
              <YuucomiSuperiorPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.BILLING.BLANK}`}>
              <BlankPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.CLIENT_NG_EDIT}>
              <ClientNgEditPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.CLIENT_NG}>
              <ClientNgPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.CORRECT_RECEIPT}/:id`}>
              <CorrectionReceiptPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.CORRECT_ENQUETE}/:id`}>
              <CorrectionEnquetePage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.ANSWER_HISTORY_LIST}/:applyId`}>
              <AnswerHistoryListPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.CORRECT_DISTRIBUTE}>
              <CorrectionDistributePage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.DATA_INPUT_DISTRIBUTE}/:monitorId`}>
              <DataInputDistributePage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.DATA_INPUT_W_CHECK_DISTRIBUTE}/:monitorId`}>
              <DataInputWCheckDstributePage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.ANSWER_LIST}/:applyId`}>
              <AnswerListPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.POINT_GRANT}>
              <PointGrantPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.POINT_GRANT}/:id`}>
              <PointGrantPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.POINT_HISTORY}/:id`}>
              <PointHistoryPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.TENSAKU.CUSTOMER_LIST}>
              <CustomerListPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.CUSTOMER_INFO}/:id`}>
              <CustomerInfoPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.RELATED_CUSTOMER}/:id`}>
              <RelatedCustomerListPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.AUTH_HISTORY}/:id`}>
              <CustomerAuthHistoryPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.AGENT_LOGIN}/:id`}>
              <AgentLoginPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.OEM_PAGE_LIST}/:customerId/:apiClientId`}>
              <IncOemTopPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.LOGIN_HISTORY}/:id`}>
              <LoginHistoryPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.MAIL_HISTORY_ROI}/:id`}>
              <MailHistoryListRoiPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.MAIL_HISTORY_OEM}/:id`}>
              <MailHistoryListOemPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.OEM_FEE_LIST}/:customerId`}>
              <OemFeeListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.BULK_REJECT}>
              <BulkRejectPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.EXTEND_DEADLINE_HISTORY}`}>
              <ExtendDeadlineHistoryPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.EXTEND_DEADLINE_REGISTER}`}>
              <ExtendDeadlineRegisterPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.TENSAKU.EXTEND_DEADLINE_REGISTER}/:id`}>
              <ExtendDeadlineRegisterPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.MAIL_BULK_DELIVERY_LIST}>
              <MailBulkDeliveryListPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.MAIL_BULK_DELIVERY_DETAIL}/:id`}>
              <MailBulkDeliveryDetailPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.CORRECT_SETTING_SEARCH}>
              <CorrectSettingSearchPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.CORRECT_SETTING_EACH_MONITOR}/:monitorId`}>
              <CorrectSettingEachMonitorPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.CORRECT_SETTING_EACH_CLIENT}/:clientId`}>
              <CorrectSettingEachClientPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.CORRECT_DATA_INPUT}/:applyId`}>
              <CorrectDataInputPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.CORRECT_DATA_WCHECK}/:applyId`}>
              <CorrectDataInputWcheckPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.CORRECT_DATA_TARGET_LIST}>
              <CorrectDataInputTargetListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.CORRECT_PHONE_MASTER}>
              <CorrectPhoneMasterPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.SURVEY_PROOF_IMAGE_LIST}/:id`}>
              <SurveyProofImageListPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_TMP}>
              <SqlTemplatePage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.SQL_TMP_INFO}/:id`}>
              <SqlTemplateInfoPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.SQL_TMP_UPDATE}/:id`}>
              <SqlTemplateUpdatePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_TMP_UPDATE_CONFIRM}>
              <SqlTemplateUpdateConfirmPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_TMP_CREATE}>
              <SqlTemplateCreatePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_TMP_CREATE_CONFIRM}>
              <SqlTemplateCreateConfirmPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_TMP_COMPLETION}>
              <SqlTemplateCompletionPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_TMP_DELETE_CONFIRM}>
              <SqlTemplateDeleteConfirmPage />
            </PrivateRoute>

            <PrivateRoute path={Url.KEISAI.API_CLIENT}>
              <ApiClientListPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.API_CLIENT_MODIFY}/:id`}>
              <ApiClientConfirmPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_QUERY}>
              <SqlQueryPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.SQL_QUERY_INFO}/:id`}>
              <SqlQueryInfoPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_QUERY_DELETE_CONFIRM}>
              <SqlQueryDeleteConfirmPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_QUERY_COMPLETION}>
              <SqlQueryCompletionPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.KEISAI.SQL_QUERY_UPDATE}/:id`}>
              <SqlQueryUpdatePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_QUERY_UPDATE_CONFIRM}>
              <SqlQueryUpdateConfirmPage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_QUERY_CREATE}>
              <SqlQueryCreatePage />
            </PrivateRoute>

            <PrivateRoute exact path={Url.KEISAI.SQL_QUERY_CREATE_CONFIRM}>
              <SqlQueryCreateConfirmPage />
            </PrivateRoute>

            <PrivateRoute exact path={`${Url.BILLING.BILLING_INVOICE_DEST_RELATION}/:id`}>
              <BillingInvoiceDestRelationPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.QC_CREW_APPLY_SEARCH}>
              <QcCrewApplySearchPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.APPLY_HISTORY_LIST}/:id`}>
              <ApplyHistoryListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.EXECUTIVE_STAFF_APPLY_SEARCH}>
              <ExecutiveStaffApplySearchPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.QC_CREW_APPLY_MANAGEMENT}>
              <QcCrewApplyManagementPage />
            </PrivateRoute>

            <PrivateRoute path={Url.TENSAKU.EXECUTIVE_STAFF_APPLY_MANAGEMENT}>
              <ExcecutiveStaffApplyManagementPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.APPLY_INFO}/:applyId/:customerIdStr`}>
              <ApplyInfoPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.RECEIPT_PRICE_MODIFY}/:applyId`}>
              <ReceiptPriceModifyPage />
            </PrivateRoute>
            <PrivateRoute path={Url.TENSAKU.RECEIPT_PRICE_MODIFY}>
              <ReceiptPriceModifyPage />
            </PrivateRoute>

            <PrivateRoute path={`${Url.TENSAKU.APPLY_INFO}/:applyId`}>
              <ApplyInfoPage />
            </PrivateRoute>

            {/* 基礎コンポーネントサンプル */}
            <PrivateRoute path="/top">
              <SampleTopPage />
            </PrivateRoute>
            {/* 一覧画面サンプル */}
            <PrivateRoute path="/list">
              <ListSample />
            </PrivateRoute>
            {/* 複数選択Listサンプル */}
            <PrivateRoute path="/select_list">
              <SelectList />
            </PrivateRoute>
            {/* 複数選択Tableサンプル */}
            <PrivateRoute path="/select_table">
              <SelectTable />
            </PrivateRoute>
            {/* 登録画面_List サンプル */}
            <PrivateRoute path="/register_list">
              <RegisterList />
            </PrivateRoute>
            {/* 登録画面_List 複雑パターンサンプル */}
            <PrivateRoute path="/register_list_complex">
              <RegisterListComplex />
            </PrivateRoute>
            {/* 登録画面_List_Card サンプル */}
            <PrivateRoute path="/register_list_card">
              <RegisterListCard />
            </PrivateRoute>
            {/* 登録画面_List_Card 複雑パターンサンプル */}
            <PrivateRoute path="/register_list_card_complex">
              <RegisterListCardComplex />
            </PrivateRoute>
            {/* 登録画面_List_Card アコーディオン版サンプル */}
            <PrivateRoute path="/register_list_card_accordion">
              <RegisterListCardAccordion />
            </PrivateRoute>
            {/* 登録画面_List_Card アコーディオン版 複雑パターンサンプル */}
            <PrivateRoute path="/register_list_card_accordion_complex">
              <RegisterListCardAccordionComplex />
            </PrivateRoute>
            {/* 登録画面_Tableサンプル */}
            <PrivateRoute path="/register_table">
              <RegisterTable />
            </PrivateRoute>
            {/* 登録画面_Table 複雑パターンサンプル */}
            <PrivateRoute path="/register_table_complex">
              <RegisterTableComplex />
            </PrivateRoute>
            {/* 登録画面_Table_Card サンプル */}
            <PrivateRoute path="/register_table_card">
              <RegisterTableCard />
            </PrivateRoute>
            {/* 登録画面_Table_Card 複雑パターンサンプル */}
            <PrivateRoute path="/register_table_card_complex">
              <RegisterTableCardComplex />
            </PrivateRoute>
            {/* 登録画面_Table_Card アコーディオン版サンプル */}
            <PrivateRoute path="/register_table_card_accordion">
              <RegisterTableCardAccordion />
            </PrivateRoute>
            {/* 登録画面_Table_Card アコーディオン版 複雑パターンサンプル */}
            <PrivateRoute path="/register_table_card_accordion_complex">
              <RegisterTableCardAccordionComplex />
            </PrivateRoute>
            <OemPrivateRoute exact path={Url.OEM.TOP}>
              <OemTopPage />
            </OemPrivateRoute>
            <OemPrivateRoute exact path={Url.OEM.EVENT_MESSAGE}>
              <OemEventMessageListPage />
            </OemPrivateRoute>
            <OemPrivateRoute exact path={Url.OEM.MONITOR_LIST}>
              <OemMonitorListPage />
            </OemPrivateRoute>
            <OemPrivateRoute exact path={Url.OEM.APPLY_INFO_LIST}>
              <OemApplyInfoListPage />
            </OemPrivateRoute>
          </Switch>
        </Router>
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default App;
